import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import { useNavigate, useParams } from "react-router-dom";
import mail from "../../../../assets/mail.png";
import location from "../../../../assets/location.png";
import { userRequest } from "../../../../requestMethod";
import { Select } from "@chakra-ui/react";
import { DatePicker, Input, message } from "antd";
import crossImg from "../../../../assets/crossImg.svg";
import UploadPartnerImage from "./UploadPartnerImage";

function ViewPartnerProfile({ setProfile, profileId }) {
  const [viewPage, setViewPage] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [photos, setPhotos] = useState([]);
  const [data, setData] = useState({});
  const [fileList, setFileList] = useState([]);
  const [rating, setRating] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [address, setAddress] = useState("");
  const navigate = useNavigate();
  console.log("rating", rating);
  const togglePage = () => {
    setViewPage(!viewPage);
  };
  const [error, setError] = useState("");

  const handleChange = (e, type) => {
    const value = e.target.value;

    // Check if the value is valid and within range
    if (type === "business") {
      setBusinessEmail(value);
    }
    if (type === "address") {
      setAddress(value);
    }
  };
  const handleRatingChange = (e) => {
    const value = e.target.value;

    // Check if the value is valid and within range
    if (value === "" || (value >= 0 && value <= 5)) {
      setRating(value);
      setError("");
    } else {
      setError("Rating must be between 0.0 and 5.0");
    }
  };

  const handleBlur = () => {
    // Automatically append .0 if a single-digit number is entered
    if (
      rating &&
      Number.isInteger(parseFloat(rating)) &&
      !rating.endsWith(".0")
    ) {
      setRating(`${rating}.0`);
      console.log("rating", rating);
    }

    // Validate on blur
    if (rating === "" || rating < 0 || rating > 5) {
      setError("Rating must be between 0.0 and 5.0");
    } else {
      setError("");
    }
  };
  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const handleRemovePicture = (index) => {
    const updatedPhotos = [...photos];
    updatedPhotos.splice(index, 1);
    setPhotos(updatedPhotos);
  };

  const handleImageUpload = (newFileList) => {
    const uploadedImages = newFileList.filter((file) => file.status === "done");
    if (uploadedImages.length > 0) {
      const updatedPhotos = [
        ...photos,
        ...uploadedImages.map((file) => file.response.url),
      ];
      setPhotos(updatedPhotos);
    }
  };
  console.log("ratings", Number(rating));

  useEffect(() => {
    // Push a new state to browser history
    window.history.pushState(null, "", window.location.pathname);

    // Handle popstate (back button) event
    const handlePopState = () => {
      setProfile(false);
    };

    window.addEventListener("popstate", handlePopState);

    // Fetch data
    userRequest(`admin/partner/getSinglePartner?partnerId=${profileId}`).then(
      (res) => {
        console.log("res?.data?", res?.data);
        setData(res?.data?.data);
        setPhotos(res.data.data.business.photos);
      }
    );

    // Cleanup
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [profileId, setProfile]);
  console.log("data", data);
  const updatedData = {
    businessId: data?.business?._id,
    photos,
    partnerId: profileId,
    category: selectedOption || data?.business?.category,
    rating: rating || data?.business?.rating,
    businessEmail: businessEmail || data?.business?.businessEmail,
    address: address || data?.business?.address,
  };
  console.log("updatedData", updatedData);
  const updatePartnerProfile = async () => {
    userRequest
      .put("admin/partner/updateProfile", updatedData)

      .then((response) => {
        message.success("Profile updated successfully");
        navigate("/Partners");
        setViewPage(!viewPage);
      })
      .catch((error) => {
        message.error("Error updating profile", error);
      });
  };

  return (
    <div className={styles.MainContainer}>
      <span className={styles.firstSpan}>
        <p onClick={() => setProfile(false)}> Active Partner</p> {">"}{" "}
        <p>
          {" "}
          <b>Profile</b>
        </p>
      </span>
      <div className={styles.PartnerHeader}>
        <div className={styles.Partner}>
          <p>{data?.business?.name}</p>
          <p>{data?.business?.category}</p>
        </div>
        {viewPage ? (
          <button onClick={togglePage}>Edit</button>
        ) : (
          <div className={styles.buttonAction}>
            <button onClick={togglePage}>Cancel</button>
            <button onClick={updatePartnerProfile}>Save</button>
          </div>
        )}
      </div>
      <div className={styles.partnerDetails}>
        <div className={styles.partnerContactDetails}>
          {viewPage ? (
            <>
              <div className={styles.partnerAddress}>
                <img src={location} alt="" />
                <p>{data?.business?.address}</p>
              </div>
              <div className={styles.partnerAddress}>
                <img src={mail} alt="" />
                <p>{data?.email}</p>
              </div>
            </>
          ) : (
            <>
              <div className={styles.partnerAddress}>
                <img src={location} alt="" />
                <Input
                  // value={address}
                  defaultValue={address || data?.business?.address}
                  onChange={(e) => handleChange(e, "address")}
                />
              </div>
              <div className={styles.partnerAddress}>
                <img src={mail} alt="" />
                <Input
                  // value={businessEmail}
                  defaultValue={businessEmail || data?.business?.businessEmail}
                  onChange={(e) => handleChange(e, "business")}
                />
              </div>
            </>
          )}
        </div>
        <div className={styles.partnerStatusDetails}>
          <div className={styles.singleDetails}>
            <p>Registered On</p>
            <p>{convertDate(data?.createdAt)}</p>
          </div>
          <div className={styles.singleDetails}>
            <p>Membership</p>
            <p>{convertDate(data?.business?.createdAt)}</p>
          </div>
          <div className={styles.singleDetails}>
            <p>Tax Number</p>
            <p>{data?.business?.taxNumber}</p>
          </div>
          <div className={styles.singleDetails}>
            <p>Ratings (0.0 - 5.0):</p>
            {!viewPage ? (
              <div>
                {/* <label htmlFor="rating">Rating (0.0 - 5.0):</label> */}
                <Input
                  type="number"
                  id="rating"
                  name="rating"
                  // value={rating}
                  defaultValue={rating || data?.business?.rating}
                  step="0.1"
                  min="0"
                  max="5"
                  onChange={handleRatingChange}
                  onBlur={handleBlur}
                  placeholder="Add Rating Point"
                />
                {error && <p style={{ color: "red" }}>{error}</p>}
                {/* <Input
                  defaultValue={data?.business?.rating}
                  value={rating}
                  type="number"
                  onChange={(el) => setRating(el.target.value)}
                  placeholder="Add Rating Point"
                /> */}
              </div>
            ) : (
              <p>{data?.business?.rating}</p>
            )}
          </div>
        </div>
        {viewPage ? null : (
          <div className={styles.editParterDetails}>
            <div className={styles.editSingleDetails}>
              <p>Type</p>
              <Select
                placeholder="Select option"
                onChange={(event) => handleOptionChange(event.target.value)}
                defaultValue={data?.business?.category}
              >
                <option value="Restaurant">Restaurant</option>
                <option value="Bar">Bar</option>
                <option value="Cafe">Cafe</option>
              </Select>
            </div>
            <div className={styles.editSingleDetails}>
              <p>New Membership</p>
              <Select placeholder="Select option" disabled></Select>
            </div>
            <div className={styles.editSingleDetails}>
              <p>Starting From</p>
              <DatePicker
                style={{
                  height: "40px",
                }}
                placement="bottomLeft"
                disabled
              />
            </div>
          </div>
        )}
        <div className={styles.partnerPics}>
          <p>Pictures</p>
          <div className={styles.picsContainer}>
            {photos.map((photo, index) => (
              <div className={styles.singlePic} key={index}>
                <img src={photo} alt="" />
                {!viewPage && (
                  <div className={styles.removeImage}>
                    <img
                      src={crossImg}
                      onClick={() => handleRemovePicture(index)}
                      alt=""
                    />
                  </div>
                )}
              </div>
            ))}
            {!viewPage && (
              <div className={styles.uploadImage}>
                <UploadPartnerImage
                  fileList={fileList}
                  setFileList={setFileList}
                  onImageUpload={handleImageUpload}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewPartnerProfile;

function convertDate(dateString) {
  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options).replace(/,/g, "");
}

import React from "react";
import styles from "../style.module.css";
import { Avatar, Pagination, message } from "antd";
import { Spinner } from "@chakra-ui/react";
import DeleteModel from "../Components/DeleteModel";
import { FaEye } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

const Premium = ({
  data,
  totalCount,
  setPage,
  loading,
  getData,
  setType,
  type,
  setViewProfile,
  setViewDetails,
  page,
  setUserId,
}) => {
  function convertDate(dateStr) {
    try {
      // Convert the input date string to a Date object
      const dateObj = new Date(dateStr);

      // Format the Date object in the desired output format
      const formattedDate = `${dateObj.getDate()}.${
        dateObj.getMonth() + 1
      }.${dateObj.getFullYear()}`;
      return formattedDate;
    } catch (error) {
      // If the input date string is not in the correct format, handle the error
      return null;
    }
  }
  const handleViewProfile = (id) => {
    setUserId(id);
    setViewProfile(true);
    setPage(page);
    setType(type);
  };
  console.log("data", data);
  console.log("type", type);
  const notAvailable = (id) => {
    setUserId(id);
    setViewDetails(true);
    setPage(page);
    setType(type);
    message.error("Currently Not available");
  };
  console.log("pro page", page);
  return (
    <div className={styles.tableDiv}>
      <TableContainer
        style={{ border: "1px solid #D9E1E7", borderRadius: "20px" }}
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>User Details</Th>
              <Th>Email</Th>
              <Th>Birthday</Th>
              <Th>Registered on</Th>
              <Th>Subscription</Th>
              <Th>Verification</Th>
              <Th>Did not attend meeting</Th>
              <Th>Action</Th>
              <Th>Feedback</Th>
            </Tr>
          </Thead>
          {loading ? (
            <span className={styles.spin}>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="lg"
              />
            </span>
          ) : (
            <Tbody>
              {data.map((el, i) => {
                console.log("el?.isPremium", el?.isPremium);
                return (
                  <Tr key={i}>
                    <Td>
                      <div className={styles.profileDetails}>
                        <div className={styles.profilePic}>
                          <Avatar name="Dan Abrahmov" src={el.images[0]} />
                          <p>{el.userName}</p>
                        </div>
                      </div>
                    </Td>
                    <Td>{el.email}</Td>

                    <Td>{convertDate(el.dob)}</Td>
                    <Td>{convertDate(el.createdAt)}</Td>
                    <Td>{el?.userSubscriptions?.isActive ? "Paid" : "Free"}</Td>
                    <Td>N/A</Td>
                    <Td>{el.verificationType}</Td>
                    <Td>
                      <div className={styles.actionDiv}>
                        {/* <Link to={`/Users/${el._id}`}> */}
                        <button
                          onClick={() => handleViewProfile(el._id)}
                          className={styles.blackBtn26}
                        >
                          <FaEye fontSize={20} /> Open Profile
                        </button>
                        {/* </Link> */}
                        <DeleteModel
                          data={el}
                          id={el._id}
                          getData={getData}
                          setType={setType}
                          page={page}
                          type={type}
                        />
                      </div>
                    </Td>
                    <Td>
                      <div className={styles.actionDiv}>
                        {/* <Link to={`/Users/view/${el._id}`}> */}{" "}
                        <button
                          onClick={() => notAvailable(el?._id)}
                          className={styles.blackBtn26}
                        >
                          <FiSearch fontSize={20} /> View details
                        </button>
                        {/* </Link> */}
                      </div>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      {totalCount > 10 && (
        <div className={styles.pagination}>
          <Pagination
            defaultCurrent={page}
            current={page}
            total={totalCount}
            onChange={(e) => setPage(e)}
            showSizeChanger={false}
          />
        </div>
      )}
    </div>
  );
};

export default Premium;

import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import styles from "./style.module.css";
import cancelImage from "../../../assets/cancelImage.png";
import suggestionList from "../../../assets/suggestionList.png";
import image1 from "../../../assets/image1.png";
import location1 from "../../../assets/location1.png";
import stars from "../../../assets/stars.png";
import inFeedList from "../../../assets/inFeedList.png";
import Ad from "../../../assets/ad.png";
import Cross from "../../../assets/cross.png";
function Preview({ data }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <span onClick={onOpen} className={styles.cancelButton}>
        Preview
      </span>

      <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
        <ModalOverlay />
        <ModalContent style={{ borderRadius: "10px" }}>
          <ModalCloseButton />
          <ModalBody>
            <div className={styles.cancelContent}>
              <div className={styles.container2}>
                {data.adType === "Suggestion" && (
                  <div className={styles.imageContainer}>
                    <img
                      src={suggestionList}
                      alt="Suggestion List"
                      className={styles.suggestionImage}
                    />
                    <div className={styles.imageInside}>
                      <div className={styles.previewText}>
                        <p>Here are some </p>
                        <p>suggestions for you !</p>
                        <p>Discover for each place.</p>
                      </div>
                      <div>
                        <div className={styles.previewInputImage}>
                          <img
                            src={data.image.length > 0 ? data.image : image1}
                            alt="Uploaded"
                          />
                        </div>
                        <div className={styles.previewAdDetails}>
                          <div className={styles.adPreviewTitle}>
                            <p>{data.name}</p>
                            <div className={styles.locations}>
                              <img src={location1} alt="location1" />
                              <p>2km</p>
                            </div>
                          </div>
                        </div>
                        <img
                          src={stars}
                          alt="location1"
                          className={styles.stars}
                        />
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        <div className={styles.previewInputImage}>
                          <img src={image1} alt="1" />
                        </div>
                        <div className={styles.previewAdDetails}>
                          <div className={styles.adPreviewTitle}>
                            <p>{data.name}</p>
                            <div className={styles.locations}>
                              <img src={location1} alt="location1" />
                              <p>2km</p>
                            </div>
                          </div>
                        </div>
                        <img
                          src={stars}
                          alt="location1"
                          className={styles.stars}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {data.adType === "Feed" && (
                  <div className={styles.imageContainer1}>
                    <img
                      src={inFeedList}
                      alt="inFeedList"
                      className={styles.suggestionImage1}
                    />
                    <div className={styles.imageInside1}>
                      <div>
                        <div className={styles.previewInputImage1}>
                          <div className={styles.div1}>
                            <img
                              src={Ad}
                              alt="1"
                              style={{
                                width: "30px",
                                position: "absolute",
                                top: "4%",
                                left: "4%",
                                borderRadius: 22,
                              }}
                            />
                            <img
                              src={Cross}
                              alt="1"
                              style={{
                                width: "30px",
                                position: "absolute",
                                right: "4%",
                                top: "4%",
                                borderRadius: 22,
                              }}
                            />
                            <div className={styles.imgBox}>
                              <img
                                src={
                                  data.image.length > 0 ? data.image : image1
                                }
                                alt="1"
                              />
                            </div>
                            <div className={styles.div2}>
                              <p className={styles.paragraph}>{data.name}</p>
                              <p className={styles.paragraph1}>{data.body}</p>
                            </div>
                            <div className={styles.div3}>
                              <button className={styles.buttons1}>
                                All Localities
                              </button>
                              <button className={styles.buttons2}>
                                More Details
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ marginTop: "10px" }}></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Preview;

import React from "react";
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  ModalHeader,
} from "@chakra-ui/react";
import styles from "./style.module.css";
import { MdCancelPresentation } from "react-icons/md";
import { message } from "antd";
import { userRequest } from "../../../../requestMethod";
import {
  calculateNextCancellationDate,
  formatDate,
  getEffectiveEndDate,
} from "../../../../utils/common";

function CancelMembership({ partnerId, partner, updateData }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const updatedData = {
    partnerId,
    status: "Deleted",
  };
  const handleCancleed = async () => {
    await updateData(updatedData);
  };
  const currentPeriodEnd = partner?.partnerSubscriptions?.expireAt;
  const currentPeriodStart = partner?.partnerSubscriptions?.startAt;

  // Convert the current period end from seconds to a Date object
  const currentStartDate = new Date(currentPeriodStart);
  const currentEndDate = new Date(currentPeriodEnd);

  // Calculate the next possible cancellation date from the start date
  const nextCancellationDate =
    !!partner?.partnerSubscriptions?.startAt &&
    calculateNextCancellationDate(currentStartDate);
  const now = new Date();
  let effectiveCancellationDate;

  // Check if the cancellation notice period is respected
  if (now < nextCancellationDate) {
    // If within the notice period, cancellation will take effect at the current period end
    effectiveCancellationDate = currentEndDate;
  } else {
    // Otherwise, extend the cancellation to the next valid end date
    effectiveCancellationDate = getEffectiveEndDate(currentEndDate);
  }
  const handleCancelSubscription = async () => {
    if (!!partner?.partnerSubscriptions?.isCancel) {
      message.error("Already canceled");
    } else {
      const cancelSubs = await userRequest.put(
        "/admin/subscription/cancelSubscription",
        { partnerId }
      );
      console.log("cancelSubs", cancelSubs);
      if (cancelSubs?.statusText === "OK") {
        // fetchData();
        message.success("Active plan cancelled successfully");
        onClose();
      }
    }
  };

  return (
    <>
      <Button
        onClick={onOpen}
        className={styles.cancelButton}
        style={{ padding: 0 }}
      >
        Cancel membership
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
        {/* <ModalOverlay /> */}
        <ModalContent
          boxShadow="dark-lg"
          borderRadius="24px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          right="-450px"
          top="100px"
        >
          <ModalHeader marginTop="46px" textAlign="center">
            🚨 Confirm Membership Cancellation
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            textAlign="center"
            display="flex"
            justifyContent="space-around"
            flexDirection="column"
            gap="20px"
            alignItems="center"
            marginBottom="30px"
          >
            <div className="flex flex-col items-center gap-4">
              <Text>
                Are you sure you want to cancel this partner's active
                membership?
              </Text>
              <p>
                The membership will remain active until{" "}
                <Text as="b">
                  {partner?.partnerSubscriptions?.expireAt
                    ? formatDate(effectiveCancellationDate)
                    : "October 18, 2024"}{" "}
                </Text>{" "}
                and terminate after this date.
              </p>
            </div>
            <button
              onClick={handleCancelSubscription}
              className="border border-black py-3 px-8 rounded-full mb-1"
              style={{
                borderWidth: "1px",
                borderColor: "rgb(0 0 0 / var(--tw-border-opacity))",
                padding: "12px 32px",
                borderRadius: "9999px",
              }}
            >
              Yes, Cancel Membership 🛑
            </button>
            <button
              onClick={onClose}
              className="bg-[#333333] text-white py-3 px-10 rounded-full mb-5"
              style={{
                borderWidth: "1px",
                color: "white",
                borderColor: "rgb(0 0 0 / var(--tw-border-opacity))",
                padding: "12px 40px",
                borderRadius: "9999px",
                backgroundColor: "#333333",
              }}
            >
              No, Keep Membership 👍
            </button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CancelMembership;

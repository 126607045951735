import React, { useState, useEffect } from "react";
import styles from "../styles.module.css";
import { Spinner } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import {
  fetchActivePartnerData,
  updateActivePartnerData,
} from "../../../Redux/Partner/ActivePartnerReducer";
import { Pagination, message } from "antd";
import { Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import ActiveTable from "./ActiveTable";

const Active = ({
  loading,
  partner,
  total,
  setPage,
  setContact,
  setProfile,
  setViewMeatings,
  setProfileId,
  setPartnerId,
  setBusinessId,
  setRouteType,
  page,
}) => {
  const [currentPage, setCurrentPage] = useState(page);
  const dispatch = useDispatch();

  // Sync currentPage with the page prop
  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  const updateData = (data) => {
    dispatch(updateActivePartnerData(data)).then((res) => {
      dispatch(fetchActivePartnerData("Active", "", 1));
      message.error("Membership canceled successfully");
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setPage(page);
  };

  function convertDate(dateStr) {
    try {
      const dateObj = new Date(dateStr);
      console.log(dateStr, "dateStr");
      const formattedDate = `${dateObj.getDate()}.${
        dateObj.getMonth() + 1
      }.${dateObj.getFullYear()}`;
      console.log("formattedDate", formattedDate);
      return formattedDate;
    } catch (error) {
      return null;
    }
  }

  const handleProfile = (profileId) => {
    setProfile(true);
    setProfileId(profileId);
  };

  const handleContact = (partnerId, type) => {
    setContact(true);
    setRouteType(type);
    setPartnerId(partnerId);
  };

  const handleView = (businessId, type) => {
    setViewMeatings(true);
    setRouteType(type);
    setBusinessId(businessId);
  };

  if (!loading && partner.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          marginTop: "10%",
        }}
      >
        No data available
      </div>
    );
  }

  return (
    <div className={styles.tableDiv}>
      <TableContainer className={styles.tableContainer}>
        <Table className={styles.table} variant="simple">
          <Thead style={{ zIndex: 10 }}>
            <Tr>
              <Th
                className={styles.tableHead}
                style={{ textTransform: "capitalize" }}
              >
                Name
              </Th>
              <Th
                className={styles.tableHead}
                style={{ textTransform: "capitalize" }}
              >
                Address
              </Th>
              <Th
                className={styles.tableHead}
                style={{ textTransform: "capitalize" }}
              >
                Legal Representative
              </Th>
              <Th
                className={styles.tableHead}
                style={{ textTransform: "capitalize" }}
              >
                No. of Meetings
              </Th>
              <Th
                className={styles.tableHead}
                style={{ textTransform: "capitalize" }}
              >
                Registered on
              </Th>
              <Th
                className={styles.tableHead}
                style={{ textTransform: "capitalize" }}
              >
                Status
              </Th>
              <Th
                className={styles.tableHead}
                style={{ textTransform: "capitalize" }}
              >
                Voucher
              </Th>
              <Th
                className={styles.tableHead}
                style={{ textTransform: "capitalize" }}
              >
                Membership
              </Th>
              <Th
                className={styles.tableHead}
                style={{ textTransform: "capitalize" }}
              >
                Type
              </Th>
              <Th style={{ minWidth: "100px", textTransform: "capitalize" }}>
                Actions
              </Th>
            </Tr>
          </Thead>
          {loading ? (
            <span className={styles.spin}>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="lg"
              />
            </span>
          ) : (
            <Tbody>
              {partner.map((el, i) => (
                <ActiveTable
                  key={i} // Add a unique key for each row
                  el={el}
                  handleContact={handleContact}
                  handleProfile={handleProfile}
                  handleView={handleView}
                  convertDate={convertDate}
                  updateData={updateData}
                />
              ))}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      {total > 10 && (
        <div className={styles.pagination}>
          <Pagination
            defaultCurrent={page}
            current={currentPage}
            total={total}
            showSizeChanger={false}
            onChange={handlePageChange}
          />
        </div>
      )}
    </div>
  );
};

export default Active;

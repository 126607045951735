import React, { useEffect } from "react";
import styles from "./styles.module.css";

const Invoice = ({ setViewMeatings }) => {
  const newArray = [...Array(10)];
  // useEffect(() => {
  //   // Push a new state to browser history
  //   window.history.pushState(null, "", window.location.pathname);

  //   // Handle popstate (back button) event
  //   const handlePopState = () => {
  //     setViewMeatings(false);
  //   };

  //   window.addEventListener("popstate", handlePopState);

  //   // Cleanup
  //   return () => {
  //     window.removeEventListener("popstate", handlePopState);
  //   };
  // }, [setViewMeatings]);
  return (
    <div className={styles.MainContainer}>
      <span>
        <span onClick={() => setViewMeatings(false)}>Active Partners</span>{" "}
        {">"} <span onClick={() => setViewMeatings(false)}>View Meetings </span>
        {">"}
        <b> Invoice History</b>
      </span>
      <div className={styles.container}>
        <div className={styles.tableHead}>
          <p className={styles.period}>Period</p>
          <p>Download</p>
        </div>
        <div className={styles.tableDiv}>
          {newArray.map((el) => {
            return (
              <div>
                <p>15.10.2023 - 15.11.2023</p>
                <p>Download</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Invoice;

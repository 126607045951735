import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  InputGroup,
  Input,
  InputRightElement,
} from "@chakra-ui/react";
import { BiSearch } from "react-icons/bi";
import NewPartner from "./NewPartner/NewPartner";
import Active from "./Active/Active";
import DeletePartner from "./DeletePartner/DeletePartner";
import BlackListPartner from "./BlackListPartner/BlackListPartner";
import { useDispatch, useSelector } from "react-redux";
import { fetchActivePartnerData } from "../../Redux/Partner/ActivePartnerReducer";
import ContactDetails from "./ContactDetails/ContactDetails";
import View from "./ViewMeetings/View";
import ViewPartnerProfile from "./Active/Profile/ViewPartnerProfile";
import { Helmet } from "react-helmet";
import ViewDeletedPartner from "./DeletePartner/Profile/ViewDeletedPartner";

const Partner = () => {
  const [contact, setContact] = useState(false);
  const [profile, setProfile] = useState(false);
  const [deleteProfile, setDeleteProfile] = useState(false);
  const [ViewMeatings, setViewMeatings] = useState(false);
  const dispatch = useDispatch();
  const [type, setType] = useState("Requested");
  const partner = useSelector((store) => store?.partner?.partner?.data) || [];
  const {
    totalBlacklisted,
    totalDeleted,
    totalRequested,
    totalActive,
    totalPartnerCount,
  } = useSelector((store) => store?.partner?.partner);
  const total = useSelector((store) => store?.partner?.partner?.totalCount);
  const loading = useSelector((store) => store?.partner?.isLoading) || false;
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [tabIndex, setTabIndex] = useState(0);
  const [businessId, setBusinessId] = useState("");
  const [partnerId, setPartnerId] = useState("");
  const [profileId, setProfileId] = useState("");
  const [routeType, setRouteType] = useState("");

  const handleTabsChange = (index) => {
    setTabIndex(index);
    setPage(1); // Reset page to 1 when tab changes

    // Set the type based on the selected tab index
    switch (index) {
      case 0:
        setType("Requested");
        break;
      case 1:
        setType("Active");
        break;
      case 2:
        setType("Deleted");
        break;
      case 3:
        setType("Blacklisted");
        break;
      default:
        setType("Requested");
    }
  };

  useEffect(() => {
    dispatch(fetchActivePartnerData(type, search, page, true));
  }, [type, search, page, dispatch]);

  if (profile) {
    return <ViewPartnerProfile setProfile={setProfile} profileId={profileId} />;
  }
  if (deleteProfile) {
    return (
      <ViewDeletedPartner
        setDeleteProfile={setDeleteProfile}
        profileId={profileId}
      />
    );
  }
  if (contact) {
    return (
      <ContactDetails
        setContact={setContact}
        partnerId={partnerId}
        routeType={routeType}
      />
    );
  }
  if (ViewMeatings) {
    return (
      <View
        setViewMeatings={setViewMeatings}
        businessId={businessId}
        routeType={routeType}
      />
    );
  }

  return (
    <div className={styles.MainContainer}>
      <Helmet>
        <title>Current Partners - NETME</title>
      </Helmet>
      <div className={styles.firstDiv}>
        <div className={styles.heading}>
          <h1>Total Partners </h1>{" "}
          <span className={styles.numberSpan}>
            {totalPartnerCount ? totalPartnerCount : 0}
          </span>
        </div>

        <InputGroup maxWidth={200} className={styles.searchBar}>
          <Input
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
          />
          <InputRightElement>
            <BiSearch color="green.500" />
          </InputRightElement>
        </InputGroup>
      </div>
      <div className={styles.main}>
        <Tabs index={tabIndex} onChange={handleTabsChange}>
          <TabList className={styles.tabList}>
            <Tab className={styles.tabPanel}>
              New Partner Requests{" "}
              <span
                id={type === "Requested" ? styles.activeTab : null}
                className={styles.numberSpan}
              >
                {totalRequested ? totalRequested : 0}
              </span>
            </Tab>
            <Tab className={styles.tabPanel}>
              Active Partners{" "}
              <span
                id={type === "Active" ? styles.activeTab : null}
                className={styles.numberSpan}
              >
                {totalActive ? totalActive : 0}
              </span>
            </Tab>
            <Tab className={styles.tabPanel}>
              Deleted Partners{" "}
              <span
                id={type === "Deleted" ? styles.activeTab : null}
                className={styles.numberSpan}
              >
                {totalDeleted ? totalDeleted : 0}
              </span>
            </Tab>
            <Tab className={styles.tabPanel}>
              Blacklist Partners{" "}
              <span
                id={type === "Blacklisted" ? styles.activeTab : null}
                className={styles.numberSpan}
              >
                {totalBlacklisted ? totalBlacklisted : 0}
              </span>
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel className={styles.tabPanels}>
              <NewPartner
                partner={partner}
                loading={loading}
                total={total}
                setPage={setPage}
                page={page}
                setContact={setContact}
                setPartnerId={setPartnerId}
                setRouteType={setRouteType}
              />
            </TabPanel>
            <TabPanel className={styles.tabPanels}>
              <Active
                partner={partner}
                loading={loading}
                total={total}
                setPage={setPage}
                page={page}
                setContact={setContact}
                setViewMeatings={setViewMeatings}
                setProfile={setProfile}
                setProfileId={setProfileId}
                setBusinessId={setBusinessId}
                setPartnerId={setPartnerId}
                setRouteType={setRouteType}
              />
            </TabPanel>
            <TabPanel className={styles.tabPanels}>
              <DeletePartner
                partner={partner}
                loading={loading}
                total={total}
                setPage={setPage}
                page={page}
                setContact={setContact}
                setRouteType={setRouteType}
                setViewMeatings={setViewMeatings}
                setBusinessId={setBusinessId}
                setPartnerId={setPartnerId}
                setProfileId={setProfileId}
                setDeleteProfile={setDeleteProfile}
              />
            </TabPanel>
            <TabPanel className={styles.tabPanels}>
              <BlackListPartner
                partner={partner}
                loading={loading}
                total={total}
                setPage={setPage}
                page={page}
                setContact={setContact}
                setRouteType={setRouteType}
                setViewMeatings={setViewMeatings}
                setBusinessId={setBusinessId}
                setPartnerId={setPartnerId}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

export default Partner;
